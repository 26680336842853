import * as React from 'react';
import { graphql } from 'gatsby';
import { Page } from '../models';
import { mapToPageModel } from '../mappers/page.mapper';
import { Helmet } from 'react-helmet';
import { IndicatorsGrid, Legend } from '../components';
import { useData } from '../contexts/useData';
import favicon from '../images/favicon.ico';

const IndexPage = ({ data: nodePage }) => {
  const pageData: Page = mapToPageModel(nodePage.nodePage);
  const { isDesktop, isBigScreen } = useData();

  return (
    <section className="vl-region">
      <Helmet>
        <title>Dashboard | Barometer samenleven</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <div className="vl-layout">
        <h1 className="vl-title vl-title--h2">{pageData.title}</h1>
        <div className="vl-grid">
          <p
            className={`vl-typography ${
              (isDesktop || isBigScreen) && 'vl-col--8-12'
            }`}
            dangerouslySetInnerHTML={{ __html: pageData.body }}
            style={{
              paddingBottom: 30,
            }}
          />
          <Legend
            className={`${(isDesktop || isBigScreen) && 'vl-col--4-12'}`}
          />
        </div>
        <IndicatorsGrid />
      </div>
    </section>
  );
};

export default IndexPage;

export const query = graphql`
  query getNodePageHome {
    nodePage(id: { eq: "5e69f208-b6df-5edc-9f65-b0a1a6ddc115" }) {
      title
      body {
        processed
      }
    }
  }
`;
